/* src/SearchBar.css */

input {
    width: 100%;
    max-width: 75%;
    margin-right: 20px;
    padding: 10px 20px;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
}

button {
    background-color: #C30A14;
    /* Red background */
    color: #fff;
    /* White text */
    border: 0;
    /* No border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 10px 20px;
    /* Increase padding to make the button bigger */
    font-size: 16px;
    /* Larger text */
    /* Optionally, you can also directly specify width and height */
    width: 100px;
    /* Specific width */
    height: 40px;
    /* Specific height */
}