/* Style the table */

.clickable {
    cursor: pointer;
}

tr.nested-row td {
    background-color: #E4DFDE;
    border: 1px solid white
}

table {
    width: 98%;
    margin: 0 auto;
    /* Center the table horizontally */
    border-collapse: collapse;
    border: 1px solid #ddd;
}

/* Style the table header (th) */
th {
    background-color: #CBC6C5;
    text-align: left;
    padding: 8px;
}

/* Style the table data cells (td) */
td {
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    padding: 8px;
    text-align: left;
}